.modal-overlay{
  max-height: 100vh;
  width: 100vw;
  overflow: hidden;
  padding: 24px;
}

.modal-content{
  height: 90vh;
  padding: 12px;
  position: relative;
  width: 100%;
}